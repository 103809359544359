 const logger =(store)=>(next)=>(action)=>{

   //  console.log(action.type)
   
    console.log('action is :',action.type)
   //  console.log("partener_:" ,action.user)
   
  //  console.log(action);
   const data =store.getState();
    // console.log("store:" ,data)
    return next(action);
}
export default logger; 