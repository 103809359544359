import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { getContentUrls } from '../../../utils/lessons';
import { Container, Button } from 'react-bootstrap';
// import TestFile from '../../../assets/test.pdf';
// import PdfViewer from './pdfViewer';
// import MyDocument from './newPdfViewer';
// import PdfComp from './testPdf';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import './pdf.css'
import "./view.css"

import { urlBase } from '../../../utils/API';
import VideoPlayer from './videoPlayer';
const MultimediaViewer = () => {



  const [pdfNumPages, setPdfNumPages] = useState(null);
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [content, setContent] = useState([]);

  const { lesson,user } = useSelector(({ view ,loggedUser}) => {
    return { lesson: view?.lesson,user:loggedUser?.user };
  });
  const getURLS = async () => {
    try {
      const res = await getContentUrls(lesson.content);
      setContent(res.data.newContent);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (lesson && lesson.content.length) {
      getURLS();
    }
  }, [lesson]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfNumPages(numPages);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  // console.log('ccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc', lesson?.content)
  return (
    <Container className='view-area-cont' onContextMenu={preventContextMenu}  id='admin-view-lesson'>
      <h2>{lesson?.name}</h2>

      {content.length ? (
        <>
          {lesson.content?.map((c, i) => {
            if (c.type.split('/').includes('image')) {
              return (
                <div key={i} onContextMenu={preventContextMenu}>
                  <hr />
                  <img
                    src={`${urlBase}/${c.name}`}
                    alt="lesson cover"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              );
            } else if (c.type.split('/').includes('video')||c.type==='m3u8') {
              return (
                <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
                  <hr />
                  {/* <video controls controlsList="nodownload" width="100%" height="auto">
                    <source src={`${urlBase}/api/files/${c.name}?type=${c?.type}`} type={c.type} />
                    Your browser does not support the video tag.
                  </video> */}
                  <VideoPlayer videoPath={c?.name} userId={user?._id} />
                </div>
              );
            } else if (c.type.split('/').includes('pdf')) {
              return (
                <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
                  {/* <DocViewer documents={[
    { url: c.url,fileType:'pdf',fileName:'pdf.pdf' }, // Remote file
  ]
} pluginRenderers={DocViewerRenderers} />
  */}
                  <div>
                      <a href={`${urlBase}/${c.name}`} ></a>
                    <div className="pdf-container">
                      <object className="pdf-object" data={`${urlBase}/${c.name}`} type="application/pdf">
                        <embed src={`${urlBase}/${c.name}`} type="application/pdf" />
                      </object>
                    </div>
                    {/* <object width={'500px'} height={'700px'} data={c.url} type='application/pdf'/> */}
                    {/* <iframe src={c.url} width="100%" height="500px" /> */}
                    {/* <PdfViewer url={c.url}/> */}
                  </div>
                  {/* <PdfComp pdfFile={c.url}/> */}
                  {/* <Document file={{url:c.url}} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
                    <Page pageNumber={pdfPageNumber} />
                  </Document>
                  <p>
                    Page {pdfPageNumber} of {pdfNumPages}
                  </p>
                  <Button
                    onClick={() => setPdfPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
                  >
                    Previous Page
                  </Button>
                  <Button
                    onClick={() => setPdfPageNumber((prevPage) => Math.min(prevPage + 1, pdfNumPages))}
                  >
                    Next Page
                  </Button> */}
                </div>
              );
            }
          })}
          <br/>
          <br/>
          <br/>
          <br/>
        </>
      ) : (
        <>
          <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
        </>
      )}

      {/* Static PDF rendering for testing */}

      {/* <PdfViewer pdfUrl={TestFile}/>
      <MyDocument/> */}
    </Container>
  );
};

export default MultimediaViewer;
