import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Table } from 'react-bootstrap';
// import "../../styles/meeting.css"
import { addSession } from '../../utils/sessions';
import { success, ErrorMsg } from '../../utils/Toast';
import GetLessonId from '../shared/GetLessonId';
import { HomePage } from '../../translations/trans';
import { IoMdAddCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ZOOM} from "../../constants"
import { TbPremiumRights } from "react-icons/tb";

function AddMeetingRoom({ packClass }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const [isOn, setIsOn] = useState(false);

    const [homeWork, setHomework] = useState("")

    const [note, setNote] = useState("");
    const [lesson, setLesson] = useState(null);
    const [date, setDate] = useState(Date.now());
    const [localDate, setLocalDate] = useState(null);
    const [homeWorkDate, setHomeWorkDate] = useState(Date.now());
    const [deadLine, setDeadLine] = useState(null);
    const [sendNotification, setSendNotification] = useState(false);

    const { packages } = useSelector(({ packages }) => ({
        packages
    }));

    const handleAddRoom = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const fetchPackage = async () => {
        const pack = packages.find(p => p._id === packClass.packID);
        setPackageData(pack);
    };

    useEffect(() => {
        fetchPackage();
    }, [packClass, packages]);




    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        // const dateWithThreeHours = addThreeHours(selectedDate);
        const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
        setLocalDate(adjustedDate.toISOString().slice(0, 16));
        const formattedDate = adjustedDate.toISOString().split('.')[0] + '+03:00';
        setDate(formattedDate);
    };

    const handleDateChangeHomeWork = (e) => {
        const selectedDate = new Date(e.target.value);
        // const dateWithThreeHours = addThreeHours(selectedDate);
        const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
        console.log(adjustedDate)
        setHomeWorkDate(adjustedDate.toISOString().slice(0, 16));
        const formattedDate = adjustedDate.toISOString().split('.')[0] + '+03:00';
        setDeadLine(formattedDate);
    };

    const createSession = async () => {
        try {
            setLoading(true)
            const res = await addSession({
                note: note,
                date: date,  // Use the formatted date here
                materialCode: packageData?.materialCode,
                lesson: lesson,
                classId: packClass._id,
                duration: "120",
                settings: {
                    auto_recording: isOn ? "cloud" : "Local"
                },
                homeWorkTask: homeWork,
                homeWorkDeadline: deadLine,
                alert: sendNotification === "true" ? true : false,
            });
            setLoading(false)
            if (res?.data?.case) {
                handleCloseModal();
                success(res?.data?.message);
            }
            else {
                ErrorMsg(res?.data?.message)
            }
        } catch (e) {
            setLoading(false)
            console.error(e);
            ErrorMsg(e?.response?.data?.message)
        }
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });




    return (
        <div>
            <Button onClick={handleAddRoom} className='btn ' style={{ background: "#4169E2" }}>
                {/* <IoMdAddCircle /> */}
                {HomePage.createSession[lang]}
            </Button>

            <Modal show={showModal} className="py-3" onHide={handleCloseModal} size='xl' dir={dir}>
                <Table responsive className='mt-5 py-3 text-center'>
                    <thead className='text-center'>
                        <tr>
                            <th>{HomePage.lessonName[lang]}</th>
                            {/* <th>الغرفه</th> */}
                            <th>{HomePage.homeWork[lang]}</th>
                            <th>{HomePage.dateAndTime[lang]}</th>
                            <th>{HomePage.materialRecord[lang]} {isOn ? "cloud" : "Local"}</th>
                            <th>{HomePage.addNote[lang]}</th>
                            <th>Whats Notification</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        <tr>
                            <td>
                                <select style={{ border: "none" }} value={lesson} onChange={(e) => setLesson(e.target.value)}>
                                    <option>{HomePage.lessonName[lang]}</option>
                                    {packageData?.selectedLessons.map((lesson) => (
                                        <option key={lesson} value={lesson}>
                                            <GetLessonId lessonId={lesson} />
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <input type="text" name="homeWork" id="homeWork" onKeyDown={(e) => {
                                    if (e.key === ' ') {
                                        e.stopPropagation();
                                    }
                                }} value={homeWork} className='form-control' onChange={(e) => setHomework(e.target.value)} />
                                {/* <input
                                    type="datetime-local"
                                    value={homeWorkDate}
                                    onChange={handleDateChangeHomeWork}
                                    style={{ border: "none", color: "#120C54" }}
                                    name="timeRoom"
                                    id="timeRoom"
                                    isutc={true}
                                /> */}
                                <DatePicker
                                    selected={homeWorkDate ? new Date(homeWorkDate) : null}
                                    onChange={(date) => {
                                        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                                        setHomeWorkDate(adjustedDate.toISOString().slice(0, 16));
                                        const formattedDate = adjustedDate.toISOString().split('.')[0] + '+03:00';
                                        setDeadLine(formattedDate);
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    className="form-control"
                                    placeholderText="Select deadline"
                                />
                            </td>

                            <td>
                                <DatePicker
                                    selected={localDate ? new Date(localDate) : null}
                                    onChange={(date) => {
                                        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                                        setLocalDate(adjustedDate.toISOString().slice(0, 16));
                                        const formattedDate = adjustedDate.toISOString().split('.')[0] + '+03:00';
                                        setDate(formattedDate);
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    className="form-control"
                                    placeholderText="Select date and time"
                                />
                                {/* <input
                                    type="datetime-local"
                                    value={localDate}
                                    onChange={handleDateChange}
                                    style={{ border: "none", color: "#120C54" }}
                                    name="timeRoom2"
                                    id="timeRoom2"
                                    isutc={true}
                                /> */}
                            </td>
                            <td>
                                <div
                                    onClick={toggleSwitch}
                                    style={{
                                        width: "50px",
                                        height: "25px",
                                        background: isOn ? "#120C54" : "gray",
                                        borderRadius: "15px",
                                        position: "relative",
                                        cursor: "pointer",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            background: "white",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            top: "2.5px",
                                            left: isOn ? "27px" : "2.5px",
                                            transition: "left 0.2s",
                                        }}
                                    ></div>
                                </div>
                            </td>
                            <td>
                                <textarea
                                    rows={"2"}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ') {
                                            e.stopPropagation();
                                        }
                                    }}
                                    cols={"15"}
                                    onChange={(e) => setNote(e.target.value)}
                                    value={note}
                                    style={{ border: "0.5px solid ", resize: "none", backgroundColor: "white", borderRadius: "5px", outline: "none" }}>
                                </textarea>
                            </td>
                            <td>
                                <select className='form-control' onChange={(e) => setSendNotification(e.target.value)}>
                                    <option hidden>Send Whats Notification</option>
                                    <option value="true">Send Notification</option>
                                    <option value="false">No Send Notification</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <button className='btn w-25 m-auto mb-3' style={{ color: "white", background: "#4169E2" }} disabled={!ZOOM} onClick={!loading ? createSession : null}>{!loading ? HomePage.create[lang] : "Loading..."}{!ZOOM?<TbPremiumRights size={'30'} color='gold'/>:null}</button>
            </Modal>
        </div>
    );
}

export default AddMeetingRoom;
