

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translations/trans';
import GetLessonId from '../../shared/GetLessonId';
import { addReportManyLesson } from '../../../utils/lessons';
import { ErrorMsg } from '../../../utils/Toast';
import { REPORTS } from '../../../constants';
import { TbPremiumRights } from "react-icons/tb";

const SendReportToAllstd = ({ packClass }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [packageData, setPackageData] = useState(null);
    const [selectedLessons, setSelectedLessons] = useState([]); // Array to hold selected lesson IDs
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);

    const { packages } = useSelector(({ packages }) => ({ packages }));
    const { lang, dir } = useSelector(({ language }) => ({
        lang: language.lang,
        dir: language.dir,
    }));

    const loggedUser = useSelector((state) => state.loggedUser);

    const fetchPackage = async () => {
        const pack = packages.find((p) => p._id === packClass.packID);
        setPackageData(pack);
    };

    const handleAddReport = async () => {
        try {
            setLoading(true);
            const res = await addReportManyLesson(
                {
                    about: type,
                    lessonIds: selectedLessons,
                },
                packClass?._id,
                loggedUser?.user?._id
            );
            handleClose();
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message || e?.response?.data);
        } finally {
            setLoading(false);
        }
    };

    const handleLessonSelect = (lessonId) => {
        if (!selectedLessons.includes(lessonId)) {
            setSelectedLessons([...selectedLessons, lessonId]);
        }
    };

    const handleLessonRemove = (lessonId) => {
        setSelectedLessons(selectedLessons.filter((id) => id !== lessonId));
    };

    useEffect(() => {
        fetchPackage();
    }, [packClass, packages]);

    return (
        <div>
            <button className='btn btn-primary' onClick={handleShow} disabled={!REPORTS}>Send Report {!REPORTS?<TbPremiumRights size={'30'} color='gold'/>:null}</button>
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Body>
                    <h3>Send Report To All Students</h3>
                    <div className='form-group'>
                        <label>{HomePage.lessonName[lang]}</label>
                        <select
                            className='form-control'
                            onChange={(e) => handleLessonSelect(e.target.value)}
                            value=""
                        >
                            <option hidden>Select Lesson</option>
                            {packageData?.selectedLessons.map((lesson) => (
                                <option key={lesson} value={lesson}>
                                    <GetLessonId lessonId={lesson} />
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className='mt-3'>
                        {selectedLessons.map((lessonId) => (
                            <div key={lessonId} className='d-flex align-items-center'>
                                <span className='mr-2'>
                                    <GetLessonId lessonId={lessonId} />
                                </span>
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleLessonRemove(lessonId)}
                                >
                                    x
                                </button>
                            </div>
                        ))}
                    </div>

                    <select className='form-control mt-3' value={type} onChange={(e) => setType(e.target.value)}>
                        <option hidden>Select Type</option>
                        <option value={"test"}>Test</option>
                        <option value={"activity"}>Activity</option>
                    </select>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}>Cancel</Button>
                    <Button variant='primary' onClick={handleAddReport} disabled={loading}>
                        {loading ? "Loading.." : "Add"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SendReportToAllstd;
