
import React, { useEffect, useRef, useState } from 'react'
// import { urlBase } from '../../utils/API';
import Hls from 'hls.js';
import { urlBase } from '../../../utils/API';

const VideoPlayer = ({ videoPath, userId }) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(`${urlBase}/${videoPath}`);
  const [error, setError] = useState(null);
  const [reload,setReload] = useState(true)

  const hlsPlayer = async ()=>{
    try{
      if (!videoUrl) return;

      if (Hls.isSupported()) {
        const hls =await new Hls({
          maxBufferSize: 30 * 1000 * 1000, // Increase buffer size
          maxBufferLength: 15,            // Buffer up to 30 seconds
          startLevel: -1,                 // Automatically select the best quality
          autoStartLoad: true,            // Ensure segments are loaded immediately
          debug:true,
          enableWorker:true,
          progressive: false,
          lowLatencyMode: true, 
          fragLoadingTimeOut: 20000, // 20 seconds timeout for fragment loading

        });
  
        hlsRef.current = hls;
  
  
        hls.config.xhrSetup = (xhr, url) => {
          if (url.includes('/keys') || url.includes('/streem-hls')) {
            // xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.withCredentials = true;
          }
        };
  
  
  
         await hls.loadSource(videoUrl);
         await hls.attachMedia(videoRef.current);
  
         hls.on(Hls.Events.MANIFEST_PARSED,async () => {
          try{
          if (videoRef.current) {
          //  await videoRef.current.play();
          }
        }
        catch(e){console.error(e)}
        });
  
        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error('HLS Error:', data);
          if (data.fatal) {
            switch (data.fatal) {
              case Hls.ErrorTypes.FRAG_PARSING_ERROR:
                setError('Fragment parsing error occurred. The video stream may be corrupted.');
                break;
              // Handle other errors as necessary
            }
          }
        });
  
      }
    }
    catch(e){
      console.error(e.message)
    }
  }
  useEffect(() => {
    hlsPlayer()
    return () => {
      hlsRef.current?.destroy();
    };
    // else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
    //   videoRef.current.src = videoUrl;
    // }
  }, [videoUrl,reload]);
  return (
    <div>
      {/* {error ? (
        <p>{error}</p>
      ) : (
        <>
          <video ref={videoRef} controls style={{ width: '100%' }} />
        </>
      )} */}
          <video ref={videoRef} controls style={{ width: '100%' }} />
          <button onClick={()=>setReload(!reload)}>Play</button>

    </div>
  )
}

export default VideoPlayer