import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { HomePage } from "../../translations/trans";
import { ErrorMsg, success } from "../../utils/Toast";
import { resetAdminPass } from '../../utils/API';

const NewPass = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const [phone, setPhone] = useState(null)
    const [oldPass, setOldPass] = useState(null)
    const [newPass, setNewPass] = useState(null)

    const handleResetPass = async () => {
        try {
            const res = await resetAdminPass({
           
                phone: phone,
                oldPassword: oldPass,
                newPassword: newPass
            })
            if (res?.data?.case) {
                success(res?.data?.message)
            }
        } catch (e) {
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        }
    }
    return (
        <div>
            <div className='w-50 m-auto shadow mt-5 mb-5' style={{ background: "white", borderRadius: "10px", padding: "10px" }}>
                <div className="" dir={`${dir}`}>
                    <div className="">
                        <div>
                            <div>
                                <label id='logla1' htmlFor='login1'>
                                    {HomePage.phone[lang]}
                                </label>
                                <br />
                                <input type='text' value={phone} name='phone' onChange={(e) => setPhone(e.target.value)} className="form-control" style={{ borderRadius: "20px" }} id='login1' />
                            </div>
                            <div>
                                <label id='logla1' htmlFor='login1'>
                                    {HomePage.oldPAss[lang]}
                                </label>
                                <br />
                                <input type='password' name='newPass' value={oldPass} onChange={(e) => setOldPass(e.target.value)} className="form-control" style={{ borderRadius: "20px" }} id='login1' />
                            </div>
                            <div>
                                <label id='logla1' htmlFor='login1'>
                                    {HomePage.Newpass[lang]}
                                </label>
                                <br />
                                <input type='password' name='code' value={newPass} onChange={(e) => setNewPass(e.target.value)} className="form-control" style={{ borderRadius: "20px" }} id='login1' />
                            </div>
                            <div className="text-center">
                                <button className="btn w-50 mt-3" onClick={handleResetPass} style={{ background: "#283476", color: "white" }}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPass
