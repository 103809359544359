import React, { useEffect, useState } from "react";
import Social from "./social";
import "./footer.css";
import Rights from "./rights";
import { NavLink } from "react-router-dom";
// import Logo from "../../assets/logo.png";
import { OLP_Logo as Logo, phone, email, footerText } from "../../constants"
import { HomePage } from "../../translations/trans";
import { useSelector, useDispatch } from "react-redux";
import { setLang } from '../../actions/language.js';

function Footer() {


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const dispatch = useDispatch();


  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  const publicPages = [
    { name: `${HomePage.home[lang]}`, to: "" },
    { name: `${HomePage.studyProgram[lang]}`, to: "/subscription" },
    // { name: "teachers", to: "/teachers" },
    { name: `${HomePage.aboutUs[lang]}`, to: "/about" },
  ];
  const quickLinks = [
    { name: `${HomePage.aboutUs[lang]}`, to: "/about" },
    // { name: "call us", to: "/" },
    { name: `${HomePage.privacy[lang]}`, to: "/privacy" },
    { name: `${HomePage.contactUs[lang]}`, to: "/contact-us" },
  ];



  return (
    <footer className="footer" dir={dir}>
      <div className="footer-logo-menu public_bg py-5">
        <div className="footer-logo-disc">
          <a href="/" rel="home-page">
            <img src={Logo} alt="logo" style={{ width: "120px" }} />
          </a>
          <p className="footer-disc" id="foot1">
            {HomePage.footerContent[lang]}
          </p>
        </div>
        <div className="footer-menu">
          <ul className="pages">
            <h2 className="menu-title" id="foot2">
              {HomePage.Abouttheplatform[lang]}
            </h2>
            {publicPages.map((p, i) => (
              <>
                <li key={i}>
                  <NavLink id={"footer" + i} to={p.to}>
                    {p.name}
                  </NavLink>
                </li>
              </>
            ))}
            <li>
              <div className="mx-3" style={{ width: "150px" }}>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    dispatch(setLang(e.target.value));
                  }}
                  value={lang}
                  style={{ background: "#283476", color: "white" }}
                >
                  <option>اختر اللغه</option>
                  <option value="AR">AR</option>
                  <option value="EN">EN</option>
                </select>
              </div>
            </li>
          </ul>
          <ul className="pages">
            <h2 className="menu-title" id="foot4">
              {HomePage.quickLinks[lang]}
            </h2>
            {quickLinks.map((p, i) => (
              <li key={i}>
                <NavLink id={"footer2" + i} className="footer-tab" to={p.to}>
                  {p.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="pages">
            <h2 className="menu-title" id="foot5">
              {HomePage.contactInformation[lang]}
            </h2>
            <li>
              <a dir="ltr" id="foot6" href={`tel:${phone}`} rel="contact-number">
                {phone}
              </a>
            </li>
            <li>
              <a
                id="footmail"
                href="#"
                rel="contact-email"
              >
                {email}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Social />
      <Rights />
    </footer>
  );
}

export default Footer;
